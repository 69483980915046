import React from "react"
import { Layout, Cart, SEO } from "../components"

function cart() {
  return (
    <Layout>
      <SEO title="Carrito - Completa tu proceso de compra" />
      <Cart />
    </Layout>
  )
}

export default cart
